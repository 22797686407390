import firebaseConfig from './firebaseConfig'
import firebase from 'firebase/compat/app'
import * as firebaseui from 'firebaseui'
import 'firebase/compat/auth'
import { AuthClient } from './clients/auth-client'

function getRedirectUrl() {
    const params = new URLSearchParams(window.location.search)
    return params.get('redirectUrl') || '/tanks'
}

// Initialize the FirebaseUI Widget using Firebase.
firebase.initializeApp(firebaseConfig)

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE)

const ui = new firebaseui.auth.AuthUI(firebase.auth())

async function onSignInSuccess(authResult: firebase.auth.UserCredential) {
    const user = authResult.user

    if (!user?.emailVerified) {
        user?.sendEmailVerification()
        alert('Please check your email for a verification link.')
        return
    }

    if (!user.email?.endsWith('@saltco.com')) {
        firebase.auth().signOut()
        alert('You must use a Salt Company email address.')
        return
    }

    const idToken = await user.getIdToken()
    try {
        await AuthClient.signIn(idToken)
    } catch (error) {
        console.error('Error during sign-in:', error)
    }

    const redirectUrl = getRedirectUrl()
    window.location.href = redirectUrl
    return
}

const uiConfig = {
    callbacks: {
        signInSuccessWithAuthResult: (authResult: any) => {
            onSignInSuccess(authResult)
            return false
        },
        signInFailure: (error: any) => {
            console.log('signInFailure', error)
        },
    },
    signInFlow: window.location.hostname == 'localhost' ? 'popup' : 'redirect',
    signInOptions: [
        {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            clientId:
                window.location.hostname == 'my.saltco.com' ||
                window.location.hostname.endsWith('saltco-c0.appspot.com')
                    ? '544719314868-ndctl5n8pd1rab4pvliugiqp77d9oe9f.apps.googleusercontent.com'
                    : '321549033862-aj62rtphp9jlvscmr9ork39gh47838p0.apps.googleusercontent.com',
            requireDisplayName: true,
        },
    ],
    tosUrl: '/terms-of-service',
    privacyPolicyUrl: '/privacy-policy',
}
// The start method will wait until the DOM is loaded.
const authContainer = document.getElementById('firebaseui-auth-container')
if (authContainer) {
    ui.start('#firebaseui-auth-container', uiConfig)
}
